<template>
  <div class="top-bar d-flex align-items-center" :class="{ 'w-100': displayFullSize }">
    <!-- <ion-button class="back" @click="back"><i class="ti-arrow-left mr-1" />Go back</ion-button> -->
    <ion-button @click="back" color="transparent" class="go-back text-black">
      <i class="ti-angle-left mr-2" />
      Back</ion-button
    >
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  displayFullSize: {
    type: Boolean,
    default: true,
  },
});
const router = useRouter();

const back = () => {
  router.back();
};
</script>

<style scoped lang="sass">
.go-back
  font-size: 16px
.top-bar
  height: 50px
  font-size: 14px
  i
    font-size: 12px
  .back
    --background: rgba(33, 65, 99, 1)
    --border-radius: 20px
</style>
